/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-central-1",
    "aws_cloud_logic_custom": [
        {
            "name": "documentsExxon",
            "endpoint": "https://dm84hrtu8h.execute-api.eu-central-1.amazonaws.com/prod",
            "region": "eu-central-1"
        }
    ],
    "aws_cognito_identity_pool_id": "eu-central-1:c2d20393-95a5-4aeb-bca3-f5d5eb09f054",
    "aws_cognito_region": "eu-central-1",
    "aws_user_pools_id": "eu-central-1_A10ZWJTiO",
    "aws_user_pools_web_client_id": "497c78m698vpt7vpt84mmm395h",
    "oauth": {},
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_content_delivery_bucket": "exxondocumentsearch-prod-cf-bucket-prod",
    "aws_content_delivery_bucket_region": "eu-central-1",
    "aws_content_delivery_url": "https://drkro8o1ng0zy.cloudfront.net"
};


export default awsmobile;
