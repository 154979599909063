import React from "react";
import { SelectField, Input, Text } from "@aws-amplify/ui-react";
import { QuarterSpliltScreen } from "./QuarterSplitScreenLayout";
import { I18n } from "aws-amplify";

function FieldSearch({ filter, setFilter, showText }) {
  const handleFieldChange = (event) => {
    const selectedValue = event.target.value;
    let newFilter = { ...filter };

    if (selectedValue === "eBoxEXXONDocumentDate") {
      newFilter = {
        ...newFilter,
        field: selectedValue,
        option: [
          I18n.get("Equals"),
          I18n.get("Earlier than"),
          I18n.get("Later than"),
        ],
        showDateInput: true,
        selectedOption: I18n.get("Equals"),
        inputValue: "",
      };
    } else if (selectedValue === "eBoxEXXONDocumentAmount") {
      newFilter = {
        ...newFilter,
        field: selectedValue,
        option: [
          I18n.get("Equals"),
          I18n.get("Does not equal"),
          I18n.get("Greater than"),
          I18n.get("Less than"),
        ],
        showDateInput: false,
        selectedOption: I18n.get("Equals"),
        inputValue: "",
      };
    } else {
      newFilter = {
        ...newFilter,
        field: selectedValue,
        option: [
          I18n.get("Contains"),
          I18n.get("Does not contain"),
          I18n.get("Equals"),
          I18n.get("Does not equal"),
          I18n.get("Begins with"),
        ],
        showDateInput: false,
        selectedOption: I18n.get("Contains"),
        inputValue: "",
      };
    }
    setFilter(newFilter);
  };

  const handleOptionChange = (event) => {
    setFilter({
      ...filter,
      selectedOption: event.target.value,
      inputValue: "",
    });
  };

  const handleInputChange = (event) => {
    setFilter({
      ...filter,
      inputValue: event.target.value,
    });
  };

  return (
    <QuarterSpliltScreen
      firstWeight={1}
      secondWeight={1}
      thirdWeight={1}
      fourthWeight={1}
    >
      {showText && (
        <Text className="search-content">
          {I18n.get("Where the property...")}{" "}
        </Text>
      )}
      <SelectField value={filter.field} onChange={handleFieldChange}>
        <option value="select">{I18n.get("(Pick Property)")}</option>
        <option value="eBoxEXXONCurrency">{I18n.get("Currency")}</option>
        <option value="eBoxEXXONCustomerNumber">
          {I18n.get("Customer Number")}
        </option>
        <option value="eBoxEXXONDocumentAmount">
          {I18n.get("Document Amount")}
        </option>
        <option value="eBoxEXXONDocumentDate">
          {I18n.get("Document Date")}
        </option>
        <option value="eBoxEXXONDocumentNumber">
          {I18n.get("Document Number")}
        </option>
        <option value="eBoxEXXONDocumentOriginatorCode">
          {I18n.get("Document Originator Code")}
        </option>
        <option value="eBoxEXXONDocumentOriginatorName">
          {I18n.get("Document Originator Name")}
        </option>
        <option value="eBoxEXXONFiscalInvoiceNumber">
          {I18n.get("Fiscal Invoice Number")}
        </option>
        <option value="eBoxEXXONOriginatorCountry">
          {I18n.get("Originator Country")}
        </option>
        <option value="eBoxEXXONReceivingCountry">
          {I18n.get("Receiving Country")}
        </option>
      </SelectField>
      <SelectField
        className="dependent-dropdown"
        value={filter.selectedOption}
        onChange={handleOptionChange}
      >
        {filter.option.map((option) => (
          <option key={option} value={option}>
            {option}
          </option>
        ))}
      </SelectField>
      {filter.showDateInput ? (
        <Input
          type="date"
          value={filter.inputValue}
          onChange={handleInputChange}
        />
      ) : (
        <Input
          type="text"
          value={filter.inputValue}
          onChange={handleInputChange}
        />
      )}
    </QuarterSpliltScreen>
  );
}

export default FieldSearch;
