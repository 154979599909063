import React from "react";
import { Button } from "@aws-amplify/ui-react";

function SupportButton() {
  const handleSupportClick = () => {
    window.location.href =
      "mailto:yifei.li@sap.com?subject=Support%20Request";
  };

  return (
    <Button
      variation="link"
      className="support-button"
      onClick={handleSupportClick}
    >
      Support
    </Button>
  );
}

export default SupportButton;
